import { JSXElement, lazy } from 'solid-js';
import {
  EnvironmentPagePath,
  getSlug,
  MyAccountPagePath,
  PublicPagePath,
  WorkspacePagePath
} from '~/components/AppRouter/utils.ts';
import { Route } from '@solidjs/router';
import {
  getWorkspacePageComponent,
  LayoutTypes
} from '~/components/Layout/utils.tsx';
import withSession from '~/components/AppRouter/withSession.tsx';
import { withFeatureFlag } from '~/utils/features/featureFlag.tsx';
import { FeatureFlagKeys } from '~/api/types/featureFlagData.ts';

const Login = lazy(() => import('~/pages/Login'));
const Home = lazy(() => import('~/pages/Home'));
const WorkspaceGeneralSettings = lazy(
  () => import('~/pages/WorkspaceGeneralSettings')
);
const ManageEnvironments = lazy(() => import('~/pages/ManageEnvironments'));
const TeamMembers = lazy(() => import('~/pages/TeamMembers'));
const Organizations = lazy(() => import('~/pages/Organization'));
const OrganizationDetails = lazy(
  () => import('~/pages/Organization/OrganizationDetails')
);
const OrganizationDetailsWithSSO = lazy(
  () => import('~/pages/Organization/OrganizationDetailsWithSSO')
);
const OrganizationDetailsWithDirSync = lazy(
  () => import('~/pages/Organization/OrganizationDetailsWithDirectorySync')
);
const SocialConnections = lazy(
  () => import('~/pages/Authentication/Connections/SocialConnections')
);
const SocialConenctionDetails = lazy(
  () =>
    import(
      '~/pages/Authentication/Connections/SocialConnections/SocialConnectionDetails'
    )
);
const CustomAttributes = lazy(() => import('~/pages/CustomAttributes'));
const Customizations = lazy(() => import('~/pages/Customizations'));
const Webhooks = lazy(() => import('~/pages/Webhooks'));
const ManageRoles = lazy(() => import('~/pages/DirectorySync/Roles'));
const ApiCredentials = lazy(() => import('~/pages/ApiCredentials'));
const MyProfile = lazy(() => import('~/pages/MyProfile'));

// @todo remove code
function getComponent(pageName: string) {
  return withSession(function PlaceHolderComponent() {
    return <>This is the {pageName} page</>;
  });
}

export default function WorkspaceAppRoutes(): JSXElement {
  return (
    <Route path={getSlug.workspaceBaseNamespace()}>
      <Route
        path={`/`}
        component={getWorkspacePageComponent(
          withSession(Home),
          LayoutTypes.EMPTY_CANVAS
        )}
      />
      {/*<Route*/}
      {/*  path={`/`}*/}
      {/*  component={getWorkspacePageComponent(*/}
      {/*    getComponent('Home'),*/}
      {/*    LayoutTypes.TWO_COLUMN_WITH_NAV*/}
      {/*  )}*/}
      {/*/>*/}
      <Route
        path={getSlug.publicPage(PublicPagePath.LOGIN)}
        component={getWorkspacePageComponent(Login, LayoutTypes.EMPTY_CANVAS)}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIROMNEMT_OVERVIEW
        )}
        component={getWorkspacePageComponent(
          getComponent('Overview'),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIROMNEMT_ORGANIZATIONS
        )}
        component={getWorkspacePageComponent(
          withSession(Organizations),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_ORGANIZATION_DETAILS_OVERVIEW
        )}
        component={getWorkspacePageComponent(
          withSession(OrganizationDetails),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_ORGANIZATION_DETAILS_CONNECTION_DETAILS
        )}
        component={getWorkspacePageComponent(
          withSession(OrganizationDetailsWithSSO),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_ORGANIZATION_DETAILS_DIRECTORY_DETAILS
        )}
        component={getWorkspacePageComponent(
          withSession(
            withFeatureFlag(
              OrganizationDetailsWithDirSync,
              FeatureFlagKeys.DIRECTORY_SYNC
            )
          ),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIROMNEMT_AUTHENTICATION
        )}
        component={getWorkspacePageComponent(
          getComponent('Authentication'),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIROMNEMT_AUDIT_LOGS
        )}
        component={getWorkspacePageComponent(
          getComponent('Audit Logs'),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIROMNEMT_SETTINGS
        )}
        component={getWorkspacePageComponent(
          getComponent('Settings'),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_SOCIAL_CONNECTIONS
        )}
        component={getWorkspacePageComponent(
          withSession(
            withFeatureFlag(
              SocialConnections,
              FeatureFlagKeys.SOCIAL_CONNECTION
            )
          ),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_SOCIAL_CONNECTION_DETAILS
        )}
        component={getWorkspacePageComponent(
          withSession(
            withFeatureFlag(
              SocialConenctionDetails,
              FeatureFlagKeys.SOCIAL_CONNECTION
            )
          ),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_SSO_CUSTOM_ATTRIBUTES
        )}
        component={getWorkspacePageComponent(
          withSession(CustomAttributes),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_CUSTOMIZATIONS
        )}
        component={getWorkspacePageComponent(
          withSession(
            withFeatureFlag(
              Customizations,
              FeatureFlagKeys.PORTAL_CUSTOMIZATION
            )
          ),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_WEBHOOKS
        )}
        component={getWorkspacePageComponent(
          withSession(withFeatureFlag(Webhooks, FeatureFlagKeys.WEBHOOKS)),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_ROLES
        )}
        component={getWorkspacePageComponent(
          withSession(
            withFeatureFlag(ManageRoles, FeatureFlagKeys.DIRECTORY_SYNC)
          ),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.environmentById(
          undefined,
          EnvironmentPagePath.ENVIRONMENT_API_CREDENTIALS
        )}
        component={getWorkspacePageComponent(
          withSession(ApiCredentials),
          LayoutTypes.TWO_COLUMN_WITH_NAV
        )}
      />
      <Route
        path={getSlug.workspace(WorkspacePagePath.WORKSPACE_GENERAL)}
        component={getWorkspacePageComponent(
          withSession(WorkspaceGeneralSettings),
          LayoutTypes.TWO_COLUMN_WITHOUT_NAV
        )}
      />
      <Route
        path={getSlug.workspace(
          WorkspacePagePath.WORKSPACE_MANAGE_ENVIRONMENTS
        )}
        component={getWorkspacePageComponent(
          withSession(
            withFeatureFlag(
              ManageEnvironments,
              FeatureFlagKeys.MANAGE_ENVIRONMENT
            )
          ),
          LayoutTypes.TWO_COLUMN_WITHOUT_NAV
        )}
      />
      <Route
        path={getSlug.workspace(WorkspacePagePath.WORKSPACE_MEMBERS)}
        component={getWorkspacePageComponent(
          withSession(TeamMembers),
          LayoutTypes.TWO_COLUMN_WITHOUT_NAV
        )}
      />
      <Route
        path={getSlug.myAccount(MyAccountPagePath.MYACCOUNT_PROFILE)}
        component={getWorkspacePageComponent(
          withSession(MyProfile),
          LayoutTypes.TWO_COLUMN_WITHOUT_NAV
        )}
      />
    </Route>
  );
}
