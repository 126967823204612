import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { API_HOST, getRequestConfig } from '~/api/utils.ts';
import { globalStore } from '~/stores';
import { AppContexts } from '~/utils/commonUtils.ts';

type ResponseTransformer<T> = (r: AxiosResponse<T>) => AxiosResponse<T>;

type RequestOptions = {
  onResponseSuccess?: ResponseTransformer<any>;
  onResponseError?: (error: any) => void;
};

export interface ScalekitAxiosConfig extends AxiosRequestConfig {
  addEnvDomain?: boolean;
  addXRequestedWith?: boolean;
}

interface ScalekitInternalAxiosRequestConfig
  extends InternalAxiosRequestConfig {
  addEnvDomain?: boolean;
  addXRequestedWith?: boolean;
}

class Request {
  private static instance: AxiosInstance;
  constructor(options?: RequestOptions) {
    Request.instance = axios.create({
      baseURL: `${API_HOST}/api/v1`,
      headers: {
        'Content-Type': 'application/json'
      },
      transformRequest: [
        function (data, headers) {
          return headers['Content-Type'] === 'application/json'
            ? JSON.stringify(
                typeof data === 'object' ? snakecaseKeys(data) : data
              )
            : data;
        }
      ],
      transformResponse: [
        function (data) {
          try {
            if (data) {
              return JSON.stringify(
                camelcaseKeys(JSON.parse(data), {
                  deep: true,
                  stopPaths: ['events']
                })
              );
            }
            return data;
          } catch (e) {
            return data;
          }
        }
      ]
    });

    // @todo use getRequestHeaders from utils
    Request.instance.interceptors.request.use(
      (config: ScalekitInternalAxiosRequestConfig) => {
        const requestConfig: ScalekitInternalAxiosRequestConfig = {
          ...getRequestConfig({}),
          ...config
        };
        if (requestConfig.addXRequestedWith) {
          requestConfig.headers['X-Requested-With'] = 'XMLHttpRequest';
        }
        if (requestConfig.addEnvDomain) {
          if (globalStore.appContext === AppContexts.SCALEKIT_DASHBOARD) {
            requestConfig.headers['x-env-domain'] =
              globalStore.currentEnvDomain;
          } else if (
            globalStore.appContext === AppContexts.CUSTOMER_PORTAL &&
            globalStore.portalAuthToken
          ) {
            requestConfig.headers['Authorization'] =
              `Bearer ${globalStore.portalAuthToken}`;
          }
        }
        return requestConfig;
      }
    );

    Request.instance.interceptors.response.use(
      (response) => {
        options?.onResponseSuccess && options.onResponseSuccess(response);
        return response;
      },
      (error) => {
        options?.onResponseError && options.onResponseError(error);
        // Let the error get forwarded to the application
        return Promise.reject(error);
      }
    );
  }

  public static getInstance(): AxiosInstance {
    if (!Request.instance) {
      new Request();
    }
    return Request.instance;
  }
}

export default Request;
