export enum QueryKeys {
  FEATURE_FLAGS = 'feature_flags',
  ENVIRONMENT_FEATURES = 'env_features',
  LOGGED_IN_MEMBER_DETAILS = 'logged_in_member_details',
  CURRENT_WORKSPACE_DETAILS = 'current_workspace_details',
  WORKSPACE_ENVIRONMENTS_LIST = 'workspace_environmets_list',
  ORGANIZATIONS = 'organizations',
  ORGANIZATIONS_SEARCH = 'organizations_search',
  CONNECTIONS = 'connections',
  DIRECTORIES = 'directories',
  ORGANIZATION_EVENTS = 'organization_events',
  DIRECTORY_USERS = 'directory_users',
  DIRECTORY_GROUPS = 'directory_groups',
  CLIENT_CREDENTIALS = 'client_credentials',
  MEMBERS_LIST = 'members_list',
  INVITE = 'invite',
  DOMAINS = 'domains',
  PORTAL_SESSION = 'portal_session',
  USER_PROFILE_ATTRIBUTES = 'user_profile_attributes',
  PORTAL_CUSTOMIZATIONS = 'portal_customizations',
  SOCIAL_CONNECTIONS = 'social_connections',
  SOCIAL_CONNECTION_DETAILS = 'social_connection_details',
  ENVIRONMENT_WEBHOOK_PORTAL_LINK = 'webhook_portal_link',
  ENVIRONMENT_ROLES = 'env_roles'
}

const getFeatureFlagsQueryKey = () => [QueryKeys.FEATURE_FLAGS];

const getEnvironmentFeaturesQueryKey = () => [QueryKeys.ENVIRONMENT_FEATURES];

const getCurrentLoggedInMemeberKey = () => [QueryKeys.LOGGED_IN_MEMBER_DETAILS];

const getCurrentWorkspaceKey = () => [QueryKeys.CURRENT_WORKSPACE_DETAILS];

const getOrganizationsKey = () => [QueryKeys.ORGANIZATIONS];

const getOrganizationsSearchKey = (query?: string) =>
  query
    ? [QueryKeys.ORGANIZATIONS_SEARCH, query]
    : [QueryKeys.ORGANIZATIONS_SEARCH];

const getOrganizationDetailsKey = (orgId: any) => [
  QueryKeys.ORGANIZATIONS,
  orgId
];

const getConnectionsKey = () => [QueryKeys.CONNECTIONS];

const getClientCredentialsKey = () => [QueryKeys.CLIENT_CREDENTIALS];

const getEnviornmentListsKey = () => [QueryKeys.WORKSPACE_ENVIRONMENTS_LIST];

const getMembersListKey = () => [QueryKeys.MEMBERS_LIST];

const getOrganizationsConnectionKey = (orgId: any) => [
  QueryKeys.ORGANIZATIONS,
  orgId,
  QueryKeys.CONNECTIONS
];

const getOrganizationsConnectionDetailsKey = (orgId: any, connId: any) => [
  ...getOrganizationsConnectionKey(orgId),
  connId
];

const getOrganizationDirectoriesListQueryKey = (orgId: any) => [
  QueryKeys.ORGANIZATIONS,
  orgId,
  QueryKeys.DIRECTORIES
];

const getOrganizationEventsQueryKey = (directoryId: string) => [
  QueryKeys.ORGANIZATION_EVENTS,
  directoryId
];

const getDirectoryUsersQueryKey = (directoryId: string) => [
  QueryKeys.DIRECTORY_USERS,
  directoryId
];

const getDirectoryGroupsQueryKey = (directoryId: string) => [
  QueryKeys.DIRECTORY_GROUPS,
  directoryId
];

const getOrganizationsDirectoryDetailsQueryKey = (
  orgId: any,
  directoryId: any
) => [...getOrganizationDirectoriesListQueryKey(orgId), directoryId];

const getOrganizationsInviteKey = (orgId: any) => [
  ...getOrganizationDetailsKey(orgId),
  QueryKeys.INVITE
];

const getOrganizationsDomainsKey = (orgId: any) => [
  ...getOrganizationDetailsKey(orgId),
  QueryKeys.DOMAINS
];

const getPortalSessionQuery = () => [QueryKeys.PORTAL_SESSION];

const getPortalCustomizationConfigQuery = () => [
  QueryKeys.PORTAL_CUSTOMIZATIONS
];

const getPortalCustomizationConfigForEnvironmentQuery = (envId: string) => [
  QueryKeys.PORTAL_CUSTOMIZATIONS,
  envId
];

const getUserProfileAttributesQuery = () => [QueryKeys.USER_PROFILE_ATTRIBUTES];

const getSocialConnectionListQuery = () => [QueryKeys.SOCIAL_CONNECTIONS];

const getSocialConnectionDetailsQuery = (connectionId?: string) => {
  const query: Array<string | QueryKeys> = [
    QueryKeys.SOCIAL_CONNECTION_DETAILS
  ];
  connectionId && query.push(connectionId);
  return query;
};

const getEnvironmentWebhookPortalQueryKey = () => [
  QueryKeys.ENVIRONMENT_WEBHOOK_PORTAL_LINK
];

const getEnvironmentRolesQueryKey = (environmentId: string) => [
  QueryKeys.ENVIRONMENT_ROLES,
  environmentId
];

const getRolesListQueryKey = () => [QueryKeys.ENVIRONMENT_ROLES];

export {
  getFeatureFlagsQueryKey,
  getEnvironmentFeaturesQueryKey,
  getCurrentLoggedInMemeberKey,
  getCurrentWorkspaceKey,
  getOrganizationsKey,
  getOrganizationsSearchKey,
  getOrganizationDetailsKey,
  getConnectionsKey,
  getClientCredentialsKey,
  getEnviornmentListsKey,
  getMembersListKey,
  getOrganizationsConnectionKey,
  getOrganizationsConnectionDetailsKey,
  getOrganizationDirectoriesListQueryKey,
  getOrganizationEventsQueryKey,
  getDirectoryUsersQueryKey,
  getDirectoryGroupsQueryKey,
  getOrganizationsDirectoryDetailsQueryKey,
  getOrganizationsInviteKey,
  getOrganizationsDomainsKey,
  getPortalSessionQuery,
  getUserProfileAttributesQuery,
  getSocialConnectionListQuery,
  getSocialConnectionDetailsQuery,
  getPortalCustomizationConfigQuery,
  getPortalCustomizationConfigForEnvironmentQuery,
  getEnvironmentWebhookPortalQueryKey,
  getEnvironmentRolesQueryKey,
  getRolesListQueryKey
};
