import { DirectoryUser } from '~/api/types/directoryData.ts';

const getEmailFromDirectoryUserPayload = (data: Record<string, any>): string =>
  data.email;

const getFullNameFromDirectoryUserPayload = (
  data: Record<string, any> | DirectoryUser
): string =>
  data.givenName || data.familyName
    ? `${data.givenName} ${data.familyName}`
    : data.preferredUsername;

const getGroupsOfAUser = (user: DirectoryUser) =>
  user.groups.map(({ displayName }) => displayName).join(', ');

export {
  getEmailFromDirectoryUserPayload,
  getFullNameFromDirectoryUserPayload,
  getGroupsOfAUser
};
